import React, { useState } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from './components/ui/card';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogAction } from './components/ui/alert-dialog';
import { Switch } from './components/ui/switch';
import { Label } from './components/ui/label';
import FlyingCakes from './components/FlyingCakes';

const CakeLotteryApp = () => {
  const [isBulkMode, setIsBulkMode] = useState(true);
  const [tickets, setTickets] = useState(isBulkMode ? Array(10).fill('') : Array(50).fill(''));
  const [isDrawing, setIsDrawing] = useState(false);
  const [winner, setWinner] = useState(null);
  const [winningTicket, setWinningTicket] = useState(null);
  const [showWinner, setShowWinner] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleModeChange = (checked) => {
    setIsBulkMode(checked);
    setTickets(checked ? Array(10).fill('') : Array(50).fill(''));
  };

  const handleNameChange = (index, value) => {
    const newTickets = [...tickets];
    newTickets[index] = value;
    setTickets(newTickets);
  };

  const drawWinner = () => {
    setIsDrawing(true);
    setShowWinner(false);
    setIsAnimating(true);

    let validTickets;
    if (isBulkMode) {
      validTickets = tickets.flatMap((name, index) =>
        name.trim() !== ''
          ? Array.from({ length: 5 }, (_, i) => ({ name, ticketNumber: index * 5 + i + 1 }))
          : []
      );
    } else {
      validTickets = tickets.map((name, index) => ({ name, ticketNumber: index + 1 }))
        .filter(ticket => ticket.name.trim() !== '');
    }

    if (validTickets.length === 0) {
      setIsDrawing(false);
      setIsAnimating(false);
      return;
    }

    let counter = 0;
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * validTickets.length);
      const selectedTicket = validTickets[randomIndex];
      setWinner(selectedTicket.name);
      setWinningTicket(selectedTicket.ticketNumber);
      counter++;

      if (counter >= 40) {
        clearInterval(intervalId);
        setIsDrawing(false);
        setShowWinner(true);
        setTimeout(() => setIsAnimating(false), 4000);
      }
    }, 100);
  };

  const renderTicketInputs = () => {
    if (isBulkMode) {
      return tickets.map((name, index) => (
        <div key={index} className="flex items-center space-x-2 mb-2">
          <span className="w-24 text-right">{index * 5 + 1}-{index * 5 + 5}:</span>
          <Input
            value={name}
            onChange={(e) => handleNameChange(index, e.target.value)}
            placeholder="Skriv navn"
            className="flex-grow"
          />
        </div>
      ));
    } else {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-5 gap-2">
          {tickets.map((name, index) => (
            <div key={index} className="flex flex-col space-y-1">
              <Label htmlFor={`ticket-${index+1}`} className="text-sm">
                Lodd {index + 1}:
              </Label>
              <Input
                id={`ticket-${index+1}`}
                value={name}
                onChange={(e) => handleNameChange(index, e.target.value)}
                placeholder="Navn"
                className="w-full"
              />
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="container mx-auto pt-10 pb-10">
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">B&Y G2014 Fotball - Kakelotteri</CardTitle>
          <div className="flex items-center space-x-2 justify-center mt-4">
            <Switch
              id="bulk-mode"
              checked={isBulkMode}
              onCheckedChange={handleModeChange}
            />
            <Label htmlFor="bulk-mode">
              {isBulkMode ? 'Bulkmodus (5 lodd per navn)' : 'Enkeltlodd'}
            </Label>
          </div>
        </CardHeader>
        <CardContent>
          {renderTicketInputs()}
        </CardContent>
        <CardFooter className="flex justify-center">
          <Button onClick={drawWinner} disabled={isDrawing}>
            {isDrawing ? 'Trekker vinner...' : 'Trekk vinner'}
          </Button>
        </CardFooter>
      </Card>

      <AlertDialog open={showWinner}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>🎂🎂🎂 Vinner trukket! 🎂🎂🎂</AlertDialogTitle>
            <AlertDialogDescription>
              Gratulerer til <span className="font-bold">{winner} 🍰</span>!
              <br />
              Vinnende loddnummer: <span className="font-bold">{winningTicket}</span>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setShowWinner(false)}>Lukk</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <FlyingCakes isAnimating={isAnimating} />
    </div>
  );
};

export default CakeLotteryApp;