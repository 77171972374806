import React from 'react';

const FlyingCakes = ({ isAnimating }) => {
  const cakes = [
    { emoji: '🍰', delay: '0s' },
    { emoji: '🎂', delay: '0.5s' },
    { emoji: '🧁', delay: '1s' },
    { emoji: '🍥', delay: '1.5s' },
    { emoji: '🍰', delay: '2s' },
    { emoji: '🎂', delay: '2.5s' },
    { emoji: '🧁', delay: '3s' },
    { emoji: '🍥', delay: '3.5s' },
    { emoji: '🍰', delay: '4s' },
    { emoji: '🎂', delay: '4.5s' },
  ];

  return (
    <div className={`fixed inset-0 pointer-events-none ${isAnimating ? '' : 'hidden'}`}>
      {cakes.map((cake, index) => (
        <div
          key={index}
          className="absolute text-7xl animate-fly"
          style={{
            left: `${Math.random() * 100}%`,
            animationDelay: cake.delay,
          }}
        >
          {cake.emoji}
        </div>
      ))}
    </div>
  );
};

export default FlyingCakes;